import React from "react";
import { Over } from "./types";

interface Props {
  currentOver: Over;
}
export function EntireOver({ currentOver }: Props) {
  const { balls, batsNames } = currentOver;
  return (
    <div className="over ng-scope" ng-if="vm.stats.currentOver">
      <p // title="Mark Wood to Devon Conway and Ruturaj Gaikwad"
        className="ng-binding"
      >
        {currentOver.bowlerName} to{" "}
        {batsNames.map(
          (bat, index) =>
            `${bat} ${index < batsNames.length - 1 ? " and " : ""}`
        )}
      </p>
      <div className="over-detail">
        <div className="ng-binding">Ov{currentOver.overNumber}:</div>
        <ol className="balls">
          {balls.map((ball, index) => (
            <li
              ng-repeat="ball in vm.stats.currentOver.balls"
              className={`ball ${ball.type}${
                ball.type === "INFIELDBOUNDARY" ? `_${ball.value}` : ""
              }`}
              key={ball.value + index + ball.type}
            >
              {ball.value}
            </li>
          ))}
        </ol>
        <div className="runs-score">
          <div className="ng-binding">
            {currentOver.runs} run{currentOver.runs > 1 && "s"}
          </div>
          <div className="ng-binding">
            {currentOver.score}-{currentOver.wickets}
          </div>
        </div>
      </div>
    </div>
  );
}
