import { EntireOver } from "./entireOver";
import React, { useEffect, useState } from "react";
import "./score.css";
import { socket } from "../../util/socket";
import { Score } from "./types";

const ScoreComponent = () => {
  const eventId = window.location.pathname.split("/event/")[1];
  const [oddSocketConnected, setOddSocketConnected] = useState(false);

  const [scoreData, setScoreData] = useState<Score | null>(null);
  const [socketTokenExpired, setSocketTokenExpired] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      fetch(`https://9xzfo.com/api/public/match-center/stats/4/${eventId}`)
        .then((res) => res.json())
        .then((item) => setScoreData(item.result));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [eventId]);
  // useEffect(() => {
  //   socket.on("ScoreUpdated", (res) => {
  //     setScoreData(res.result);
  //     setSocketTokenExpired(false);
  //   });
  //   socket.on("JoinedScoreSuccessfully", () => {
  //     setOddSocketConnected(true);
  //   });
  // }, []);

  // useEffect(() => {
  //   socket.connect();
  //   socket.on("connect", () => {
  //     setOddSocketConnected(false);
  //   });
  //   socket.on("ScoreTokenExpired", () => {
  //     setSocketTokenExpired(true);
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  //   useEffect(() => {
  //     let timer = setInterval(
  //       () =>
  //         !oddSocketConnected &&
  //         socket.emit("JoinScoreRoom", {
  //           eventId,
  //         }),
  //       1000
  //     );
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, [oddSocketConnected, eventId]);

  // if (!oddSocketConnected) {
  //   return <>Loading ....</>;
  // }

  if (!scoreData)
    return (
      <>
        <h2>No Data Found Yet</h2>
      </>
    );
  const { stats } = scoreData;
  const {
    tossWinner,
    battingTeam,
    currentOver,
    lastOver,
    lastBatsmanOut,
    batsmen,
    bowler,
  } = stats;

  if (
    !battingTeam &&
    !tossWinner?.name &&
    !tossWinner?.decision &&
    !currentOver &&
    !lastOver &&
    Array.isArray(bowler) &&
    !(batsmen?.length > 0)
  )
    return <>No Data Found</>;
  // if (socketTokenExpired) {
  //   return (
  //     <>
  //       <h1>Under Maintenaince</h1>
  //       <p>Sorry for the trouble , the service will start shortly.</p>
  //     </>
  //   );
  // }
  return (
    <div className="markets">
      <div className="header">
        <div className="match-centre ng-scope" ng-if="vm.state.hasStats">
          <div className="event-header">
            {/* <h1 className="ng-binding">
              <i className="apl-icon-custom-play"></i> Chennai Super Kings v
              Lucknow Super Giants
            </h1> */}
            <i
              analytics-on=""
              analytics-event="Expand"
              analytics-category="Match Stats"
              analytics-label="Expand Match Centre"
              className="match-stats-body-button apl-icon-chevron-up"
              ng-click="vm.toggleStatsBody()"
              ng-if="!vm.isIconHidden()"
            ></i>
          </div>

          <div
            className="match-centre-header ng-scope"
            ng-if="vm.isHeadVisible"
          >
            <div className="toss">
              {battingTeam && (
                <h2
                  ng-if="vm.stats.battingTeam !== null"
                  className="ng-binding ng-scope"
                >
                  {battingTeam.name}, {battingTeam.score}-{battingTeam.wickets}{" "}
                  ({battingTeam.overs} Ovs)
                </h2>
              )}
              {tossWinner && (
                <p className="ng-binding">
                  <span className="ng-binding">
                    {stats.currentInning % 2 === 1
                      ? `${tossWinner?.name || ""} ${
                          tossWinner?.decision || ""
                        }`
                      : `${battingTeam?.name} needs ${battingTeam?.target} runs.`}
                  </span>
                </p>
              )}
            </div>
            <div>
              {battingTeam && (
                <table
                  ng-if="vm.stats.battingTeam !== null"
                  className="ng-scope"
                >
                  <thead>
                    <tr className="_align-center">
                      <th translate={undefined} className="ng-scope ng-binding">
                        {battingTeam.target > 0 ? "RRR" : "PROJ SCR"}
                      </th>
                      <th translate={undefined} className="ng-scope">
                        P'SHIP
                      </th>
                      <th className="ng-binding">CRR</th>
                      <th translate={undefined} className="ng-scope">
                        LAST WKT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="_align-center ng-binding">
                        {battingTeam.target > 0
                          ? battingTeam.requiredRunRate
                          : battingTeam?.projScr || "-"}
                      </td>
                      <td className="_align-center ng-binding">
                        <span
                          ng-if="vm.stats.battingTeam.partnerShipBalls"
                          className="ng-binding ng-scope"
                        >
                          {battingTeam?.partnerShipScore}(
                          {battingTeam?.partnerShipBalls})
                        </span>
                      </td>
                      <td className="_align-center ng-binding">
                        {battingTeam?.runRate}
                      </td>
                      <td className="_align-center ng-binding">
                        {lastBatsmanOut
                          ? `${lastBatsmanOut.name} ${lastBatsmanOut.batsManRuns} (${lastBatsmanOut.balls}) `
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {stats.status === "InPlay" && (
            <div
              className="match-centre-body ng-scope"
              ng-if="vm.isBodyVisible"
            >
              <div className="overs">
                {currentOver && <EntireOver currentOver={currentOver} />}
                {lastOver && <EntireOver currentOver={lastOver} />}
              </div>
              <div
                ng-if="vm.stats.bowler &amp;&amp; vm.stats.batsmen.length > 0"
                className="ng-scope"
              >
                <table>
                  <tbody>
                    {batsmen?.length > 0 && (
                      <tr className="row-highlight">
                        <td translate={undefined} className="ng-scope">
                          Batsmen
                        </td>
                        <td
                          translate={undefined}
                          className="_align-center ng-scope"
                        >
                          R
                        </td>
                        <td
                          translate={undefined}
                          className="_align-center ng-scope"
                        >
                          B
                        </td>
                        <td
                          translate={undefined}
                          className="_align-center ng-scope"
                        >
                          4s
                        </td>
                        <td
                          translate={undefined}
                          className="_align-center ng-scope"
                        >
                          6s
                        </td>
                        <td
                          translate={undefined}
                          className="_align-center ng-scope"
                        >
                          SR
                        </td>
                      </tr>
                    )}

                    {batsmen?.map((batter) => (
                      <tr
                        ng-repeat="batsman in vm.stats.batsmen track by $index"
                        className="ng-scope"
                        key={
                          batter.name + batter.isOnStrike + batter.strikeRate
                        }
                      >
                        <td className="ng-binding">
                          {batter.name}
                          {batter.isOnStrike && "*"}
                        </td>
                        <td className="_align-center ng-binding">
                          {batter.batsmanRuns}
                        </td>
                        <td className="_align-center ng-binding">
                          {batter.balls}
                        </td>
                        <td className="_align-center ng-binding">
                          {batter.fours}
                        </td>
                        <td className="_align-center ng-binding">
                          {batter.sixes}
                        </td>
                        <td className="_align-center ng-binding">
                          {batter.strikeRate}
                        </td>
                      </tr>
                    ))}

                    {!Array.isArray(bowler) && (
                      <tr className="row-highlight">
                        <td translate={undefined} className="ng-scope">
                          Bowler
                        </td>
                        <td
                          className="_align-center ng-scope"
                          translate={undefined}
                        >
                          O
                        </td>
                        <td
                          className="_align-center ng-scope"
                          translate={undefined}
                        >
                          M
                        </td>
                        <td
                          className="_align-center ng-scope"
                          translate={undefined}
                        >
                          R
                        </td>
                        <td
                          className="_align-center ng-scope"
                          translate={undefined}
                        >
                          W
                        </td>
                        <td
                          className="_align-center ng-scope"
                          translate={undefined}
                        >
                          ECO
                        </td>
                      </tr>
                    )}
                    {bowler && !Array.isArray(bowler) && (
                      <tr>
                        <td className="ng-binding">{bowler.name}</td>
                        <td className="_align-center ng-binding">
                          {bowler.overs}
                        </td>
                        <td className="_align-center ng-binding">
                          {bowler.maidens}
                        </td>
                        <td className="_align-center ng-binding">
                          {bowler.bowlerRuns}
                        </td>
                        <td className="_align-center ng-binding">
                          {bowler.wickets}
                        </td>
                        <td className="_align-center ng-binding">
                          {bowler.economy}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScoreComponent;
