import React from "react";
import "./App.css";
import ScoreComponent from "./components/score/scoreComponent";

function App() {
  const searchPramams = new URLSearchParams(document.location.search);
  console.log(searchPramams, searchPramams.get("theme"));
  return (
    <div className={`App ${searchPramams.get("theme")}`}>
      <ScoreComponent />
    </div>
  );
}

export default App;
